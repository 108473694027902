<template>
   <div>

       <div class="main">
           <agent-info-comp></agent-info-comp>
           <div class="search">
               <div class="refresh" @click="search"><i class="fa fa-refresh"></i></div>
               <!--파트너 선택-->
               <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
               <!--검색조건선택-->
               <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="applyCash.byField"
                          placeholder="검색조건">
                   <el-option :value="agentConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
               </el-select>
               <el-input v-model="applyCash.searchString" size="mini" style="width: 150px;margin-left: 5px"
                         placeholder="검색내용입력"></el-input>
               <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>

               <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>


           </div>
           <div class="data">
               <div style="padding: 5px 0;font-weight: bold;width: 100%">
                   회원:{{totalUserCount}}명, 총{{totalCount}}회, 총출금액:{{totalCash |comma}}
               </div>

               <table class="agent_table">
                   <tr>
                       <th>총판</th>
                       <th>닉네임</th>
                       <th>금액</th>
                       <th>신청일짜</th>
                       <th>상태</th>
                   </tr>
                   <tr v-for="item in applyCashList">
                       <td>
                           <div v-if="null != item.agent2">
                               {{item.agent2.nickname}}
                           </div>
                       </td>
                       <td> {{item.user.nickname}}</td>
                       <td>
                           <span style="display: inline-block;padding: 2px;border-radius: 3px;width: 95%" class="text-red">{{(item.amount * -1)|comma}}</span>
                       </td>
                       <td>
                           {{item.createTime|datef('MM월DD일 HH:mm')}}
                       </td>
                       <td>
                           <span v-if="item.status == agentConst.ApplyCash.STATUS_WAITING"
                                 class="badge badge-primary">미확인</span>
                           <span v-if="item.status == agentConst.ApplyCash.STATUS_CHECKING"
                                 class="badge badge-primary">확인중</span>
                           <span v-if="item.status == agentConst.ApplyCash.STATUS_COMPLITE"
                                 class="text-blue">완료</span>
                           <span v-if="item.status == agentConst.ApplyCash.STATUS_CANCEL" class="text-red">취소</span>
                       </td>
                   </tr>
               </table>
           </div>
           <div class="pagePanel">
               <el-pagination
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageNum"
                       :page-sizes="[20, 50, 100, 150, 200]"
                       :page-size="20"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="pageTotal">
               </el-pagination>
           </div>
       </div>
   </div>


</template>

<script>

    import {Loading} from "element-ui";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import {getApplyList2} from "../../network/agent/commonRequest";
    import agentConst from "../../common/agent/agentConst";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";
    import AgentTopbarComp from "../../components/agent/AgentTopbarComp";

    export default {
        name: "AgentApplyExchange",
        components: {AgentTopbarComp, AgentInfoComp, PartnerSubSelectorComp, DateSelectorComp},
        mixins: [agentMixin],
        data() {
            return {
                applyCash: {user: {}},
                applyCashList: [],
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                selectedPartnerId: null,
                totalCount: 0,
                totalUserCount: 0,
                totalCash: 0,

            }
        },
        methods: {
            search() {
                this.getApplyCashList();
            },

            getApplyCashList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.applyCash.startDate = this.startDate;
                this.applyCash.endDate = this.endDate;
                this.applyCash.applyType = agentConst.ApplyCash.ATYPE_EXCHANGE
                getApplyList2(this.applyCash, this.pageNum, this.pageSize).then(res => {
                    console.log(res)
                    this.pageTotal = res.data.total;
                    this.applyCashList = res.data.data.cashList;
                    this.totalCash = res.data.data.totalCash
                    this.totalCount = res.data.data.totalCount
                    this.totalUserCount = res.data.data.totalUserCount
                    loadingInstance.close();
                })
            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.search();
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.applyCash = {}
                this.applyCash.partnerId2 = partnerId
                this.search()
            },
        },
        created() {
            this.startDate = this.$moment().format('yyyy-MM-DD')
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.search()
        },
        watch: {}
    }
</script>

<style scoped>

</style>